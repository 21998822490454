var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('HomeStyled', [_c('div', {
    staticClass: "panel welcome"
  }, [_c('strong', [_vm._v("Hi " + _vm._s(_vm._f("capitalize")(_vm.username)) + ", Nice to see you again!")])]), _c('div', {
    staticClass: "panel users-non-talpa"
  }, [_vm._v("Non Talpa Users: " + _vm._s(_vm.usersTotal - _vm.usersTalpa))]), _c('div', {
    staticClass: "panel users-talpa"
  }, [_vm._v("Talpa Users: " + _vm._s(_vm.usersTalpa))]), _c('div', {
    staticClass: "panel assets"
  }, [_vm._v("Assets: " + _vm._s(_vm.assetsCount))]), _c('div', {
    staticClass: "panel assets"
  }, [_vm._v("Un-Verified Users: " + _vm._s(_vm.unverifiedUsersCount))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }