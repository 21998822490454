<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <HomeStyled v-else>
    <div class="panel welcome">
      <strong>Hi {{ username | capitalize }}, Nice to see you again!</strong>
    </div>
    <div class="panel users-non-talpa">Non Talpa Users: {{ usersTotal - usersTalpa }}</div>
    <div class="panel users-talpa">Talpa Users: {{ usersTalpa }}</div>
    <div class="panel assets">Assets: {{ assetsCount }}</div>
    <div class="panel assets">Un-Verified Users: {{ unverifiedUsersCount }}</div>
  </HomeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'
import { flexCenter } from '@styles/mixins'
import { getUsernameFromToken, getRolesFromToken } from '@common/utils'
import { TalpaLoaderWrapper } from '@common/components'

import PROFILE_QUERY from '#/graphql/profile/show.gql'
import USER_COUNT_QUERY from '#/graphql/users/userCount.gql'
import UNVERIFIED_USERS_QUERY from '#/graphql/users/unverifiedUsers.gql'
import MACHINE_RECORD_COUNT_QUERY from '#/graphql/operations/assets/machinesRecordCount.gql'

const HomeStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'welcome welcome'
    'users-non-talpa users-talpa'
    'assets assets';
  background: ${p => chroma(p.theme.colors.black).alpha(0.5).css()};
  margin: 3rem;
  .panel {
    ${flexCenter}
    margin: 2rem 1rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  }
  .welcome {
    grid-area: welcome;
  }
`

export default {
  components: {
    HomeStyled,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      profile: null,
      usersTalpa: 0,
      usersTotal: 0,
      unverifiedUsers: [],
    }
  },
  computed: {
    username() {
      return get(this.profile, 'name', getUsernameFromToken(this.$keycloak.token))
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasUserPermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    unverifiedUsersCount() {
      return this.unverifiedUsers && this.unverifiedUsers.filter(a => a.requiredActions.length > 0).length
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
    usersTalpa: {
      query: USER_COUNT_QUERY,
      variables() {
        return {
          where: {
            email: 'talpa',
          },
        }
      },
      update: ({ userCount }) => userCount,
      skip() {
        return !this.hasUserPermission
      },
    },
    usersTotal: {
      query: USER_COUNT_QUERY,
      update: ({ userCount }) => userCount,
      skip() {
        return !this.hasUserPermission
      },
    },
    assetsCount: {
      query: MACHINE_RECORD_COUNT_QUERY,
      update: ({ machinesRecordCount }) => machinesRecordCount,
    },
    unverifiedUsers: {
      query: UNVERIFIED_USERS_QUERY,
      update: ({ users }) => users,
    },
  },
}
</script>
